<template>
	<div class="serialMargin" v-if="serialList != ''">
		<div class="artist__legend_bar"><strong>Цуврал кино уран бүтээл</strong></div>
		<div class="row">
			<GenericMovieCard v-for="(serial, index) in serialList" :key="index" :type="'serial'" :slug="serial.serial_slug" :poster="serial.serial_poster" :year="serial.premiere_date" :role="serial.role" :job="serial.job" />
		</div>
	</div>
</template>

<script>
import axios from "axios";
import GenericMovieCard from "@/components/artist/GenericMovieCard.vue";

export default {
	props: {
		slug: String,
	},
	components: {
		GenericMovieCard,
	},
	data() {
		return {
			artistSlug: this.slug,
			serialList: [],
		};
	},
	methods: {
		serialArtists() {
			axios.get("/artist/api-artist-serials/", { params: { slug: this.artistSlug } }).then((response) => {
				this.serialList = response.data.serials;
			});
		},
	},
	created() {
		this.serialArtists();
	},
	watch: {
		slug() {
			this.serialList = [];
			this.artistSlug = this.slug;
			this.serialArtists();
		},
	},
};
</script>

<style scoped>
.serialMargin {
	margin: 20px 5px 0;
}

.artist__legend_bar {
	color: #0066b5;
	font-size: 28px;
	text-transform: uppercase;
	font-weight: 300;
	margin: auto;
	width: 100%;
}

.row {
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	gap: 0.5rem;
	width: 100%;
	padding: 0.5rem;
	margin: 0 auto;
}

@media (min-width: 481px) {
	.row {
		grid-template-columns: repeat(8, minmax(0, 1fr));
	}
}
</style>
