<template>
    <div>
        <legend :class="routeName === 'best-movie-list' || routeName ===  'best-serial-list' ? 'generic-list__legendbar' : 'generic-list__legendbar__normal'" id="generic-list__title"><strong>{{ listTitle }}:</strong></legend>
        <div v-if="userSlug == requestUserSlug">
            <router-link :to="movieList ? { name: 'movie-list-update', params: { listSlug: listSlug }} : { name: 'serial-list-update', params: { listSlug: listSlug }}">
                <v-btn class="generic-list__edit" dark>засах</v-btn>
            </router-link>
            <v-dialog v-model="listDeleteDialog" persistent max-width="290">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn class="generic-list__delete" dark v-bind="attrs" v-on="on">устгах</v-btn>
                </template>
                <v-card>
                    <v-spacer></v-spacer>
                    <v-card-text class="pa-2 generic-list__delete_txt">Та энэ жагсаалтыг устгах гэж байна!</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red darken-1" text @click="listDeleteDialog = false; deleteList();">УСТГАХ</v-btn>
                        <v-btn color="blue darken-1" text @click="listDeleteDialog = false">БУЦАХ</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
        <div class="generic-list__maintitle">{{ contentList.title }}</div>
        <div class="generic-list__like_btn">
            <button @click="likeList()">
                <v-icon class="generic-list__icon">{{ reviewHeart ? 'mdi-heart' : 'mdi-heart-outline' }}</v-icon> <small>{{ reviewCount }}</small>
            </button>
        </div>
        <p class="generic-list__content">{{ contentList.about }}</p>
        <div v-if="!genericDesc(contentList)">
            <v-card v-for="(list_movie, index) in genericList(contentList)" :key="index" class="mb-3">
                <div class="generic-list__card_box">
                    <div class="generic-list__img_block">
                        <router-link :to="{ name: detailUrl, params: { slug: genericSlug(list_movie) }}">
                            <img class="rounded generic-list__img" :src="baseUrl + '/media/' + genericPoster(list_movie)"/>
                        </router-link>
                    </div>
                    <div class="generic-list__txt_block">
                        <div class="generic-list__txt_number">{{ index + page + ( 4 * ( page - 1 ))  }}</div>
                        <div class="generic-list__txt_content">{{ list_movie.description }}</div>
                    </div>
                </div>
            </v-card>
        </div>
        <div v-if="genericDesc(contentList)" class="generic-list__row">
            <div v-for="(list_movie, index) in genericList(contentList)" :key="index">
                <router-link :to="{ name: detailUrl, params: { slug: genericSlug(list_movie) }}">
                    <div class="generic-list__avatar_block ">
                        <img class="rounded generic-list__column" :src="baseUrl + '/media/' + genericPoster(list_movie)"/>
                        <v-chip class="generic-list__avatar">{{ index + page + ( 24 * ( page - 1 )) }}</v-chip>
                    </div>
                </router-link>
            </div>
        </div>
        <v-pagination v-model="page" :length="itemPage" @input="getGenericList()" :total-visible="7"></v-pagination>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    props: {
        slug: String,
        listSlug: String
    },
    data(){
        return{
            baseUrl: process.env.VUE_APP_BASEURL,
            listTitle: '',
            routeName: this.$route.name,
            
            isAuth: this.$store.state.user.isAuthenticated,
            userSlug: this.$store.state.user.slug,
            requestUserSlug: this.slug,
            contentList: {},

            reviewHeart: '',
            reviewCount: 0,

            listDeleteDialog: false,

            page: 1,    
            itemPage: 0
        }
    },
    computed:{
        bestMovieList(){
            return this.routeName === 'best-movie-list'
        },
        movieList(){
            return this.routeName === 'user-movie-list-detail'
        },
        detailUrl(){
            return (this.bestMovieList || this.movieList) ? 'movie-detail' : 'serial-detail'
        }
    },
    watch: {
        $route(to, from){
            this.routeName = to.name
            this.page = 1
            this.itemPage = 0
            this.contentList = {}
            this.getGenericList()
        }
    },
    methods:{
        genericList(a){
            return (this.bestMovieList || this.movieList) ? a.movie_list : a.serial_list
        },
        genericDesc(a){
            return (this.bestMovieList || this.movieList) ? a.movie_desc ? true : false : a.serial_desc ? true : false
        },
        genericSlug(a){
            return (this.bestMovieList || this.movieList) ? a.movie_slug : a.serial_slug
        },
        genericPoster(a){
            return (this.bestMovieList || this.movieList) ? a.movie_poster : a.serial_poster
        },
        getGenericList(){
            if (this.bestMovieList) {
                this.listTitle = document.title = 'Шилдэг кино жагсаалт'

                axios
                    .get('/user/api/v-user-topmovielist/', { params: { slug: this.requestUserSlug, page: this.page }})
                    .then((response) => { this.contentList = response.data.movie_list, this.reviewCount = this.contentList.movie_list_likes, this.reviewHeart = this.contentList.user_like, this.itemPage = response.data.page })
            } else if (this.routeName === 'best-serial-list') {
                this.listTitle = document.title = 'Шилдэг цувралын жагсаалт'

                axios
                    .get('/user/api/v-user-topseriallist/', { params: { slug: this.requestUserSlug, page: this.page }})
                    .then((response) => { this.contentList = response.data.serial_list, this.reviewCount = this.contentList.serial_list_likes, this.reviewHeart = this.contentList.user_like, this.itemPage = response.data.page })
            } else if (this.movieList) {
                this.listTitle = document.title = 'Кино жагсаалт'

                axios
                    .get('/user/api/v-user-movielistdetail/', { params: { slug: this.requestUserSlug, list: this.listSlug }})
                    .then((response) => { this.contentList = response.data.movie_list, this.reviewCount = this.contentList.movie_list_likes, this.reviewHeart = this.contentList.user_like  })
            } else if (this.routeName === 'user-serial-list-detail') {
                this.listTitle = document.title = 'Цувралын жагсаалт'

                axios
                    .get('/user/api/v-user-seriallistdetail/', { params: { slug: this.requestUserSlug, list: this.listSlug }})
                    .then((response) => { this.contentList = response.data.serial_list, this.reviewCount = this.contentList.serial_list_likes, this.reviewHeart = this.contentList.user_like})
            }
        },
        likeList(){
            if(this.isAuth){
                if(this.reviewHeart == true){
                    this.reviewHeart = false
                    this.reviewCount--
                } else{
                    this.reviewHeart = true
                    this.reviewCount++
                }
                if (this.routeName === 'best-movie-list') {
                    axios
                        .post('/user/api/v-user-movielistlike/', { list_id: this.contentList.id, list_user: this.requestUserSlug })
                } else if (this.routeName === 'best-serial-list') {
                    axios
                        .post('/user/api/v-user-seriallistlike/', { list_id: this.contentList.id, list_user: this.requestUserSlug })
                }
                
            }
        },
        deleteList(){
            let deleteURL = this.movieList ? '/movie/api-delete-movielist/' : '/serial/delete-seriallist/'
            axios
                .post(deleteURL, {list_slug: this.listSlug})
                .then(response => {
                    if(response.status !== 404){
                        this.$router.push({ name: this.movieList ? 'user-movie-list' : 'user-serial-list', params: { slug: this.slug }})
                        this.$store.commit('SET_SNACK', { message: 'Жагсаалт усгагдлаа.', color: 'green' })
                    } else {
                        this.$store.commit('SET_SNACK', { message: 'Алдаа гарлаа. Та дахин оролдож үзнэ үү!', color: 'red' })
                    }
                })
        }
    },
    created(){
        this.getGenericList()
    }
}
</script>

<style>

.generic-list__legendbar,
.generic-list__legendbar__normal {
    border-bottom: #B29600 1px solid;
    color: #B29600;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 300;
    margin: auto;
    width: 100%;
    margin-bottom: 5px;
}

.generic-list__legendbar__normal {
    border-bottom: #0066b5 1px solid;
    color: #0066b5;
}


.generic-list__edit {
    color: #FFF !important;
    background-color: #0066b5 !important;
    margin-right: 5px
}

.generic-list__delete {
    color: #FFF !important;
    background-color: #F00 !important;
}

.generic-list__delete_txt {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
}

.generic-list__maintitle {
    text-align: center;
    font-size: 36px;
    font-weight: 400;
}

.generic-list__like_btn {
    text-align: right;
    margin-bottom: 20px;
}

.generic-list__icon {
    font-size: 20px;
    color: #B29600 !important;
}

.generic-list__content {
    color: #696969;
    padding: 5px;
}

.generic-list__card_box {
    display: flex;
    flex-wrap: wrap;
}

.generic-list__img_block {
    flex: 100%;
}

.generic-list__txt_block {
    padding: 15px;
}

.generic-list__img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.generic-list__txt_number {
    font-size: 24px;
    font-weight: 300px;
    color: #696969;
    font-style: italic;
}

.generic-list__txt_content {
    color: #696969;
    font-size: 18px;
    word-wrap: anywhere;
}

.generic-list__row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100% !important;
    padding: 0.5rem;
    margin: 0 auto;
    gap: 0.5rem;
}

.generic-list__column {
    width: 100%;
}

.generic-list__avatar_block {
    position: relative;
}

.generic-list__avatar {
    z-index: 1;
    position: absolute;
    left: 5px;
    top: 5px;
    font-style: italic;
    color: #FFF !important;
    background-color: #0066b5 !important;
    font-weight: bold;
}

@media (min-width: 481px) {

    .generic-list__img_block {
        flex: 25%;
    }

    .generic-list__txt_block {
        flex: 75%;
    }

    .generic-list__row {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

}

</style>