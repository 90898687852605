<template>
	<div class="v-cloak--hidden" v-show="serial || user.contributer">
		<div id="movie-info__wrapper">
			<div class="block movie-poster__box">
				<img :src="baseUrl + serial.poster" class="rounded" width="300" />
				<v-btn block class="movie-review__dlg_btn" @click="favoriteUser">
					<span class="movie-review__dlg_btn_txt">
						<v-icon>{{ userFavorite ? "mdi-heart" : "mdi-heart-outline" }}</v-icon>
					</span>
				</v-btn>
				<!-- <v-btn height="42px" color="#0066b5" v-show="user.isAuthenticated && userWatched" x-small outlined class="mx-1"><v-icon>mdi-check</v-icon></v-btn> -->
				<v-dialog v-model="commentDialog" max-width="640" min-width="320">
					<template v-slot:default="commentDialog">
						<v-card>
							<v-container class="text-center">
								<div class="movie_review__login_txt">
									Та эхлээд
									<span class="movie_review__login_link" @click="loginRequired()">НЭВТЭРНЭ</span>
									үү!
								</div>
							</v-container>
							<v-card-actions class="justify-end"><v-btn text @click="commentDialog.value = false" color="#0066b5">Хаах</v-btn></v-card-actions>
						</v-card>
					</template>
				</v-dialog>
			</div>
			<div class="block movie-background__box">
				<img class="movie-background__image" :src="baseUrl + serial.background" />
				<div v-show="serial.m_title || serial.i_title" class="movie-background__block">
					<div class="movie-background__title">{{ serial.m_title ? serial.m_title : serial.i_title }}</div>
					<div v-show="serial.re_year" class="movie-background__year">
						{{ serial.re_year }}
						<span v-if="!(serial.re_year === serial.fi_year)">
							-
							<span v-if="serial.fi_year">{{ serial.fi_year }}</span>
							<span v-else>одоог хүртэл</span>
						</span>
					</div>
				</div>
			</div>
			<div class="block movie-info__box">
				<div v-show="serialClassicFlag || serial.grade || serial.a_grade" class="movie-grade">
					<v-card elevation="3">
						<div class="movie-grade__box" @click="gotoId">
							<div v-show="serial.grade" :class="serial.grade && serial.a_grade ? 'movie-grade__block movie-grade__block__divider' : 'movie-grade__block'">
								<div class="movie-grade__critic">{{ serial.grade }}%</div>
								<div class="movie-grade__rate">
									<v-rating :value="parseInt(serial.grade) * 0.05" color="#B29600" background-color="#B29600" length="5" :empty-icon="emptyIcon" :full-icon="fullIcon" :half-icon="halfIcon" dense half-increments readonly size="32"></v-rating>
									{{ serial.critics }} шүүмжлэгч
								</div>
							</div>
							<div v-show="serial.a_grade" class="movie-grade__block">
								<div class="movie-grade__audience">{{ serial.a_grade }}%</div>
								<div class="movie-grade__rate">
									<v-rating :value="parseInt(serial.a_grade) * 0.05" color="#0066b5" background-color="#0066b5" length="5" :empty-icon="emptyIcon" :full-icon="fullIcon" :half-icon="halfIcon" dense half-increments readonly size="32"></v-rating>
									{{ serial.audiences }} кино сонирхогч
								</div>
							</div>
						</div>
					</v-card>
					<div v-show="serialClassicFlag" class="movie-classic">MFI Classics</div>
				</div>
				<div class="movie-info__title_m" v-show="serial.m_title">{{ serial.m_title }}</div>
				<div class="movie-info__title_io" v-show="serial.i_title">{{ serial.i_title }}</div>
				<div class="movie-info__title_io" v-show="serial.i_title && serial.i_title !== serial.o_title && serial.m_title !== serial.o_title">{{ serial.o_title }}</div>
				<div class="movie-info__block">
					<small>
						<div v-show="serial.rating" class="movie-info__rating">
							<v-btn x-small text outlined class="">
								<span>{{ serial.rating }}</span>
							</v-btn>
						</div>
						<span v-show="serial.format" class="movie-info__fltr">
							<v-icon class="movie-info__icon">mdi-filmstrip</v-icon>
							{{ serial.format }} |
						</span>
						<span v-show="serial.languages" class="movie-info__fltr">
							<v-icon class="movie-info__icon">mdi-volume-high</v-icon>
							<span v-for="(language, index) in serial.languages" :key="'language' + index">
								{{ language["language_mongol_name"] }}
								<span v-show="index + 1 < serial.languages.length">,</span>
							</span>
							|
						</span>
						<span v-show="serial.re_year">
							{{ serial.re_year }}
							<span v-if="!(serial.re_year === serial.fi_year)">
								-
								<span v-if="serial.fi_year">{{ serial.fi_year }}</span>
								<span v-else>одоог хүртэл</span>
							</span>
							|
						</span>
						<span v-show="serial.trailer">
							<a class="movie-info__fltr" target="_blank" :href="serial.trailer"><v-icon>mdi-youtube</v-icon></a>
							|
						</span>
						<span v-show="serial.runtime" class="movie-info__fltr">
							<v-icon class="movie-info__icon">mdi-clock-time-three</v-icon>
							{{ serial.runtime }}' |
						</span>
						<span v-show="serial.countries">
							<span v-for="(country, index) in serial.countries" :key="'country' + index">
								{{ country["country_mongol_name"] }}
								<span v-show="index + 1 < serial.countries.length">,</span>
							</span>
						</span>
					</small>
				</div>
				<div class="movie-info__block">
					<small>
						<span class="movie-info__genre" v-for="(genre, index) in serial.genres" :key="'genre' + index">
							{{ genre["genre_mongol_name"] }}
							<span v-show="index + 1 < serial.genres.length">,</span>
						</span>
					</small>
				</div>
				<div class="movie-info__block">
					<span class="movie-info__plot">{{ serial.plot }}</span>
				</div>
			</div>
		</div>
		<div class="movie-content">
			<div class="movie-content__block_aa">
				<div class="movie-content__box">
					<GenericAwardList :type="'serial'" :slug="slugSerial" />
					<GenericArtistList :title="'Уран бүтээлчид:'" :btnName="'Бусад уран бүтээлчид'" :type="'serial'" :format="'artist'" :slug="slugSerial" />
					<GenericArtistList :title="'Дүрүүд:'" :btnName="'Бусад дүрүүд'" :type="'serial'" :format="'role'" :slug="slugSerial" />
				</div>
			</div>
			<div class="movie-content__box">
				<SeasonList :published="true" :slug="slugSerial" id="season-list" />
				<SeasonList :published="false" :slug="slugSerial" />
				<GenericMovieList :title="'Төстэй цувралууд:'" :type="'serial'" :format="'similar'" :slug="slugSerial" />
				<GenericMovieList :title="'Танд таалагдаж магадгүй:'" :type="'serial'" :format="'suggested'" :slug="slugSerial" />
				<GenericListedSerials :type="'serial'" :slug="slugSerial" />
				<GenericJournals :type="'serial'" :slug="slugSerial" />
			</div>
		</div>
		<div v-if="user.isAuthenticated && user.contributer && user.adminBarHide" class="subadmin__box">
			<v-dialog v-model="publishDialog" width="500">
				<template v-slot:activator="{ on, attrs }">
					<button :class="publish ? 'subadmin__publish' : 'subadmin__unpublish'" v-bind="attrs" v-on="on"><v-icon class="subadmin__icon">mdi-clipboard-check-outline</v-icon></button>
				</template>
				<v-card>
					<v-card-title class="headline grey lighten-2 mx-auto">Цуврал нийтлэх</v-card-title>
					<v-card-text class="mt-3">
						Та "{{ serial.i_title }}"
						<span v-if="publish">цувралын нийтлэгдснийг болиулах</span>
						<span v-else>цувралыг нийтлэх</span>
						гэж байна!
					</v-card-text>
					<v-divider></v-divider>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							:color="publish ? 'red' : 'primary'"
							text
							@click="
								publishDialog = false;
								publishSerial();
							"
						>
							{{ publish ? "болиулах" : "нийтлэх" }}
						</v-btn>
						<v-btn color="warning" text @click="publishDialog = false">буцах</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<router-link class="subadmin__update" :to="{ name: 'serial-update', params: { serialSlug: slugSerial } }"><v-icon class="subadmin__icon">mdi-lead-pencil</v-icon></router-link>
		</div>
	</div>
</template>
<script>
import axios from "axios";
import { mapState } from "vuex";

import GenericAwardList from "@/views/generics/movie_serial/GenericAwardList.vue";
import GenericArtistList from "@/views/generics/movie_serial/GenericArtistList.vue";
import GenericMovieList from "@/views/generics/movie_serial/GenericMovieList.vue";
import GenericListedSerials from "@/views/generics/movie_serial/GenericListedMovies.vue";
import GenericJournals from "@/views/generics/movie_serial/GenericJournals.vue";
import SeasonList from "@/views/serial/detail/SeasonList.vue";

export default {
	props: {
		slug: String,
	},
	components: {
		GenericAwardList,
		GenericArtistList,
		GenericListedSerials,
		GenericJournals,
		GenericMovieList,
		SeasonList,
	},
	data() {
		return {
			commentDialog: false,
			serialClassicFlag: null,
			slugSerial: this.slug,
			baseUrl: process.env.VUE_APP_BASEURL,
			serial: {},
			emptyIcon: "mdi-star-outline",
			fullIcon: "mdi-star",
			halfIcon: "mdi-star-half-full",
			userWatch: false,
			userWatched: false,
			userFavorite: false,
			publish: "",
			publishDialog: false,
		};
	},
	computed: mapState(["user"]),
	methods: {
		gotoId() {
			var el = document.getElementById("season-list");
			if (typeof el !== "undefined" && el !== null) {
				el.scrollIntoView({ behavior: "smooth" });
			}
		},
		getSerialDetail() {
			axios
				.get("/serial/api-serial-detail/", { params: { slug: this.slugSerial } })
				.then((response) => {
					(this.serial = response.data.serial_detail), (this.publish = this.serial.publish), (document.title = this.serial["i_title"]), (this.userWatch = response.data.user_watch), (this.userFavorite = response.data.user_favorite);
				})
				.catch((error) => {
					if (error.response.status === 404) {
						this.$router.push({ name: "not-found-404" });
					}
				});
		},
		favoriteUser() {
			if (this.user.isAuthenticated) {
				if (this.userFavorite == false) {
					this.userFavorite = true;
				} else {
					this.userFavorite = false;
				}
				axios.post("/serial/api-favorite-serial/", { serial: this.slugSerial });
			} else this.commentDialog = true;
		},
		watchUser() {
			if (this.user.isAuthenticated) {
				if (this.userWatch == false) {
					this.userWatch = true;
				} else {
					this.userWatch = false;
				}
				axios.post("/serial/api-watch-serial/", { serial: this.slugSerial });
			}
		},
		publishSerial() {
			if (this.user.isAuthenticated) {
				if (this.publish == false) {
					this.publish = true;
				} else {
					this.publish = false;
				}
				axios.post("/serial/api-publish-serial/", { serial: this.slug });
			}
		},
		loginRequired() {
			if (!this.user.isAuthenticated) {
				this.$router.push({ name: "login", query: { redirect: { name: "serial-detail", params: { slug: this.slugSerial } } } });
			}
		},
	},
	created() {
		this.getSerialDetail();
	},
	watch: {
		slug() {
			window.scrollTo(100, 100);
			this.slugSerial = this.slug;
			this.serial = {};
			this.getSerialDetail();
		},
	},
};
</script>

<style scoped src="@/assets/styles/movie_serial.css"></style>
