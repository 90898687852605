<template>
	<div class="festival__box">
		<div class="festival__poster_block">
			<img class="festival__poster" src="https://www.mfi.mn/media/home/ggiff_2024_poster_small.jpg" />
		</div>
		<div>
			<div class="festival__poster_txt_bgd">
				<div class="festival__txt_block">
					<router-link :to="{ name: 'festival-list' }" class="festival__previos_block">
						<div class="festival__txt"><v-icon class="festival__register_icon" size="40">mdi-rewind</v-icon>{{ previous }}</div>
					</router-link>
					<!-- <span v-show="language === 'EN'">
					<div class="festival__txt" @click="getGuideline"><v-icon class="festival__register_icon" size="40">mdi-stop</v-icon>Rules and regulations</div>
					<div class="festival__about_block" ref="festival__about_block">
						<div class="festival__about_txt">
							<div v-if="error" class="festival__error_msg">
								{{ error }}
							</div>
							<div v-else v-html="this.guidance.guidance_en"></div>
						</div>
					</div>
				</span> -->
				</div>
				{{ title }}
			</div>
			<div v-show="language === 'MN'" class="festival__register_block">
				<v-stepper v-model="step" vertical>
					<v-stepper-step :complete="step > 1" step="1">
						<span class="register_step_name" @click="step = 1">Бүртгүүлэхийн өмнө</span>
					</v-stepper-step>

					<v-stepper-content step="1">
						<v-card class="mb-12 pa-2" outlined>
							<div>
								<p><span>Сайн байна уу, Алтан Өргөө олон улсын кино наадам 2024-ын Монгол уралдаант хэсгийн сонгон шалгаруулалтад киногоо бүртгүүлэхээр зэхэж буй танд баярлалаа!</span></p>
								<p class="register_first_text">
									<span>Та киногоо бүртгүүлэхээс өмнө удирдамжтай сайтар танилцана уу.</span>
								</p>
								<p class="register_first_text">
									<span>Бүртгүүлэхээс өмнө дараах зүйлс ЗААВАЛ хэрэг болохыг анхаарна уу:</span>
								</p>
								<ul>
									<li><span>Найруулагчийн хөрөг зураг</span><span> (2:3 </span><span>харьцаатай </span><span>JPEG)</span></li>
									<li><span>Киноны зурагт хуудас &ndash; постер </span><span>(2:3 </span><span>харьцаатай </span><span>JPEG)</span></li>
									<li><span>Киног илэрхийлэх кадр </span><span>(</span><span>16</span><span>:</span><span>9</span><span> </span><span>харьцаатай </span><span>JPEG)</span></li>
									<li><span>Киноны эцсийн файлыг 1080p .mp4 эсвэл 1080p .mov форматтай аль нэг хуваалцах (sharing) сайт дээр байршуулсан байх.</span></li>
								</ul>
								<p class="register_first_text">
									<span>Хэрвээ хүсвэл дараах зүйлсийг мөн нэмж бэлдэж болно:</span>
								</p>
								<ul>
									<li><span>Бусад уран бүтээлчдийн хөрөг зураг</span><span> (2:3 </span><span>харьцаатай </span><span>JPEG)</span></li>
									<li><span>Киноны трейлерийн </span><span>Youtube </span><span>холбоос.</span></li>
									<li><span>Киноны Англи хадмал орчуулга (srt format).</span></li>
								</ul>
								<p class="register_first_text">
									<span>Эдгээрийг бэлдсэн бол дараагийн алхам руу шилжиж киногоо бүртгүүлнэ үү.</span>
								</p>
								<p class="register_first_text">
									<span>Үүнээс гадна бүртгүүлсэн киноны болон уран бүтээлчдийн мэдээлэл хойшид mfi.mn сайт дээр байрших болохыг анхаарна уу.</span>
								</p>
								<p>
									<span>Энэхүү сайт дээр байршсанаар таны киноны мэдээлэл Монголын кино институтийн бүрдүүлж эхэлж буй Монголын кино урлагийн төвлөрсөн мэдээллийн системийн нэг хэсэг болох завшаан тохиож буй гэдгийг онцолмоор байна.</span>
								</p>
								<p><span>Танд амжилт хүсье</span><span>!</span></p>
							</div>
							<img class="register_guidance_img" src="https://www.mfi.mn/media/home/ggiff_guidance.jpg" />
						</v-card>
						<v-btn color="success" @click="step = 2"> Эхлэх </v-btn>
					</v-stepper-content>

					<v-stepper-step :complete="step > 2" step="2">
						<span class="register_step_name">Наадмын удирдамж</span>
					</v-stepper-step>

					<v-stepper-content step="2">
						<v-card class="mb-12 pa-2" outlined>
							<div v-html="this.guidance.guidance"></div>
						</v-card>
						<a href="https://form.mfi.mn" target="_blank"><v-btn color="success" @click="step = 3"> Бүртгүүлэх </v-btn></a>
						<v-btn text @click="step = 1"> Буцах </v-btn>
					</v-stepper-content>
				</v-stepper>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
	name: "Home",
	data() {
		return {
			step: 0,
			dates: [],
			baseUrl: process.env.VUE_APP_BASEURL,
			guidance: "",
			error: "",
			openExpn: false,
		};
	},
	computed: {
		date() {
			return new Date().getFullYear() - 2013;
		},
		title() {
			return this.language === "MN" ? "Алтан өргөө олон улсын кино наадам 2025" : "Golden Ger International Film Festival 2025";
		},
		previous() {
			return this.language === "MN" ? "Өмнөх наадмууд" : "Previous editions";
		},
		guideName() {
			return this.language === "MN" ? "Удирдамж" : "";
		},
		...mapState(["language"]),
	},
	created() {
		document.title = "MFI";
		this.getGuideline();
	},
	methods: {
		getGuideline() {
			this.openExpn = !this.openExpn;
			axios
				.get("/award/registration-guidance/", {
					params: {
						name: "Golden Ger international film festival",
						year: 2025,
					},
				})
				.then((response) => {
					this.guidance = response.data;
					console.log(this.guidance);
				})
				.catch((error) => {
					if (this.openExpn) {
						if (error.response) {
							this.error = error.response.data.message;
							this.$store.commit("SET_SNACK", {
								message: error.response.data.message,
								color: "red",
							});
						}
					}
				});
			// .finally(() => {
			// 	var b = this.$refs.festival__about_block;
			// 	b.style.maxHeight ? (b.style.maxHeight = null) : (b.style.maxHeight = b.scrollHeight + "px");
			// });
		},
	},
};
</script>
<style scoped>
.festival__box {
	min-width: 320px;
	max-width: 480px;
}

.festival__poster_txt_bgd {
	padding: 15px;
	font-size: 36px;
	font-weight: 300;
	color: darkgoldenrod;
	text-transform: uppercase;
	text-align: center;
}

.festival__poster_block {
	width: 100%;
}

.festival__poster {
	width: 100%;
	object-fit: cover;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.festival__txt_block {
	margin: 10px;
	font-size: 32px;
	text-transform: uppercase;
	font-weight: 400;
}

.festival__txt {
	color: #656565;
	cursor: pointer;
	transition: 5s;
}

.festival__register {
	display: inline;
	padding: 10px 20px;
	border: 5px solid #0066b5;
}

.festival__register_icon {
	color: #cd5c5c;
	margin-right: 15px;
}

.festival__url_no_decoration {
	text-decoration: none;
	color: #0066b5;
}

.festival__register_link {
	margin-top: 20px;
	margin-bottom: 20px;
	float: right;
}

.festival__about_block {
	padding: 0 18px;
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.5s ease-out;
}

.festival__error_msg {
	color: red;
	font-size: 24px;
}

.festival__previos_block {
	max-height: 0;
	overflow: hidden;
	text-decoration: none;
}

.festival__about_txt {
	padding: 15px 20px;
	border-top: 1px solid #696969;
	border-bottom: 1px solid #696969;
	font-size: 16px;
}

.register_step_name {
	font-size: 24px;
	font-weight: 600;
	text-transform: uppercase;
	cursor: pointer;
}

.festival__register_block {
	margin: 20px auto;
	max-width: 1280px;
	min-width: 320px;
}

@media (min-width: 481px) {
	.festival__box {
		max-width: 100%;
	}

	.festival__poster {
		width: 25%;
	}
}
</style>
