import Vue from "vue";
import VueRouter from "vue-router";
import createWebHistory from "vue-router";
import store from "../store/index.js";

import UnderConstruction from "../views/UnderConstruction.vue";
import Home from "../views/home/Home.vue";
import MFIHome from "../views/MFIHome.vue";
import DBHome from "../views/DBHome.vue";
import AboutUs from "../views/institute/aboutus/Detail.vue";
import EditAboutUs from "../views/institute/aboutus/Edit.vue";

import Login from "../views/Login.vue";
import Signup from "../views/Signup.vue";
import Activate from "../views/Activate.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import ChangePhone from "../views/ChangePhone.vue";
import ResetPassword from "../views/ResetPassword.vue";

import NotFound404 from "../views/NotFound404.vue";

import UserDetail from "../views/user/Detail.vue";
import UserImages from "../views/user/UserUploadedImages.vue";
import UserFeed from "../views/user/UserFeed.vue";

import GenericReviewList from "../views/user/GenericReviewList.vue";
import GenericUserListedMovies from "../views/user/GenericListedMovies.vue";
import GenericMSAList from "../views/user/GenericMSAList.vue";
import GenericUserMovieList from "../views/user/GenericMovieList.vue";
import GenericJournals from "../views/user/GenericJournals.vue";

import FestivalList from "../views/festival/List.vue";

import AwardCreate from "../views/festival/base/Create.vue";
import AwardUpdate from "../views/festival/base/Update.vue";
import AwardDetail from "../views/festival/base/Detail.vue";
import AnnualAwardCreate from "../views/festival/annual/Create.vue";
import AnnualAwardUpdate from "../views/festival/annual/Update.vue";
import AnnualAwardDetail from "../views/festival/annual/Detail.vue";
import AnnualAwardGuidelines from "../views/festival/annual/Guidelines.vue";
import AnnualAwardRegister from "../views/festival/annual/Register.vue";
import AnnualAwardAgenda from "../views/festival/agenda/Detail.vue";

import MSListCreate from "../views/generics/movie_serial/GenericCreateMSList.vue";
import MSListUpdate from "../views/generics/movie_serial/GenericUpdateMSList.vue";

import ArtistList from "../views/artist/List.vue";
import ArtistDetail from "../views/artist/Detail.vue";
import ArtistCreate from "../views/artist/Create.vue";
import ArtistUpdate from "../views/artist/Update.vue";
import AddArtist from "../views/artist/AddArtist.vue";

import MovieList from "../views/movie/List.vue";
import MovieDetail from "../views/movie/Detail.vue";
import MovieCreate from "../views/movie/Create.vue";
import MovieUpdate from "../views/movie/Update.vue";
import MovieImageUpdate from "../views/movie/update/UpdateImage.vue";
import AddMovie from "../views/movie/AddMovie.vue";

import SerialList from "../views/serial/List.vue";
import SerialDetail from "../views/serial/Detail.vue";
import SerialCreate from "../views/serial/Create.vue";
import SerialUpdate from "../views/serial/Update.vue";
import AddSerial from "../views/serial/AddSerial.vue";
import UpdateSerial from "../views/serial/UpdateSerial.vue";

import SeasonDetail from "../views/season/Detail.vue";
import SeasonCreate from "../views/season/Create.vue";
import SeasonUpdate from "../views/season/Update.vue";
import SeasonImageUpdate from "../views/season/update/UpdateImage.vue";

import EpisodeCreate from "../views/episode/Create.vue";
import EpisodeUpdate from "../views/episode/Update.vue";
import EpisodeDetail from "../views/episode/Detail.vue";

import JournalList from "../views/journal/List.vue";
import JournalDetail from "../views/journal/Detail.vue";
import JournalCreate from "../views/journal/Create.vue";
import JournalUpdate from "../views/journal/Update.vue";

import GenericDetailedReviewList from "../views/review/GenericReviewList.vue";
import GenericDetailedReview from "../views/review/GenericReviewDetail.vue";

Vue.use(VueRouter);

const routes = [
  { path: "/", name: "home", component: Home },
  { path: "/submission", name: "mfi-home", component: MFIHome },
  { path: "/home", name: "db-home", component: DBHome },
  { path: "/login", name: "login", component: Login },
  { path: "/signup", name: "signup", component: Signup },
  { path: "/activate", name: "activate-user", component: Activate },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPassword,
  },
  { path: "/change-phone", name: "change-phone", component: ChangePhone },
  {
    path: "/password/reset/confirm/:uid/:token",
    name: "reset-password",
    component: ResetPassword,
  },

  {
    path: "/under-construction",
    name: "under-construction",
    component: UnderConstruction,
  },

  {
    path: "/user/:slug",
    component: UserDetail,
    props: true,
    meta: { requireLogin: true },
    children: [
      { path: "", name: "user-feed", component: UserFeed, props: true },
      {
        path: "best-movie-list",
        name: "best-movie-list",
        component: GenericUserListedMovies,
        props: true,
      },
      {
        path: "best-serial-list",
        name: "best-serial-list",
        component: GenericUserListedMovies,
        props: true,
      },
      {
        path: "movie-reviews",
        name: "movie-reviews",
        component: GenericReviewList,
        props: true,
      },
      {
        path: "episode-reviews",
        name: "episode-reviews",
        component: GenericReviewList,
        props: true,
      },
      {
        path: "movie-detailed-reviews",
        name: "movie-detailed-reviews",
        component: GenericMSAList,
        props: true,
      },
      {
        path: "episode-detailed-reviews",
        name: "episode-detailed-reviews",
        component: GenericMSAList,
        props: true,
      },
      {
        path: "favorite-movies",
        name: "favorite-movies",
        component: GenericMSAList,
        props: true,
      },
      {
        path: "favorite-short-movies",
        name: "favorite-short-movies",
        component: GenericMSAList,
        props: true,
      },
      {
        path: "favorite-serials",
        name: "favorite-serials",
        component: GenericMSAList,
        props: true,
      },
      {
        path: "reacted-movies",
        name: "reacted-movies",
        component: GenericMSAList,
        props: true,
      },
      {
        path: "reacted-serials",
        name: "reacted-serials",
        component: GenericMSAList,
        props: true,
      },
      {
        path: "watch-movies",
        name: "watch-movies",
        component: GenericMSAList,
        props: true,
      },
      {
        path: "watch-serials",
        name: "watch-serials",
        component: GenericMSAList,
        props: true,
      },
      {
        path: "movie-list",
        name: "user-movie-list",
        component: GenericUserMovieList,
        props: true,
      },
      {
        path: "movie-list/:listSlug",
        name: "user-movie-list-detail",
        component: GenericUserListedMovies,
        props: true,
      },
      {
        path: "serial-list",
        name: "user-serial-list",
        component: GenericUserMovieList,
        props: true,
      },
      {
        path: "serial-list/:listSlug",
        name: "user-serial-list-detail",
        component: GenericUserListedMovies,
        props: true,
      },
      { path: "idols", name: "idols", component: GenericMSAList, props: true },
      {
        path: "published-journals",
        name: "published-journals",
        component: GenericJournals,
        props: true,
        meta: { requirePublisher: true },
      },
      {
        path: "unpublished-journals",
        name: "unpublished-journals",
        component: GenericJournals,
        props: true,
        meta: { requirePublisher: true },
      },
    ],
  },
  {
    path: "/user-images/:slug",
    name: "user-images",
    component: UserImages,
    props: true,
    meta: { requireLogin: true },
  },

  { path: "/festival", name: "festival-list", component: FestivalList },
  {
    path: "/festival/create",
    name: "award-create",
    component: AwardCreate,
    meta: { requireAdmin: true },
  },
  {
    path: "/festival/update/:slug",
    name: "award-update",
    component: AwardUpdate,
    props: true,
    meta: { requireAdmin: true },
  },
  {
    path: "/festival/detail/:slug",
    name: "award-detail",
    component: AwardDetail,
    props: true,
  },
  {
    path: "/festival/annual/create",
    name: "annual-award-create",
    component: AnnualAwardCreate,
    meta: { requireAdmin: true },
  },
  {
    path: "/festival/annual/update/:slug/:year",
    name: "annual-award-update",
    component: AnnualAwardUpdate,
    props: true,
    meta: { requireAdmin: true },
  },
  {
    path: "/festival/annual/detail/:slug/:year",
    name: "annual-award-detail",
    component: AnnualAwardDetail,
    props: true,
  },
  {
    path: "/festival/annual/agenda/:slug/:year",
    name: "annual-award-agenda",
    component: AnnualAwardAgenda,
    props: true,
  },
  {
    path: "/festival/annual/guidelines",
    name: "annual-award-guidelines",
    component: AnnualAwardGuidelines,
  },
  {
    path: "/festival/annual/register",
    name: "annual-award-register",
    component: AnnualAwardRegister,
    meta: { requireLogin: true },
  },

  { path: "/artist", name: "artist-list", component: ArtistList },
  { path: "/mongol-artist", name: "mongol-artist-list", component: ArtistList },
  {
    path: "/artist/:slug/detail",
    name: "artist-detail",
    component: ArtistDetail,
    props: true,
  },
  { path: "/artist/create", name: "artist-create", component: ArtistCreate },
  {
    path: "/artist/:slug/update",
    name: "artist-update",
    component: ArtistUpdate,
    props: true,
  },

  { path: "/movie", name: "movie-list", component: MovieList },
  { path: "/short-movie", name: "short-movie-list", component: MovieList },
  { path: "/mongol-movie", name: "mongol-movie-list", component: MovieList },
  {
    path: "/mongol-short-movie",
    name: "mongol-short-movie-list",
    component: MovieList,
  },
  {
    path: "/movie/:slug/detail",
    name: "movie-detail",
    component: MovieDetail,
    props: true,
  },
  { path: "/movie/create", name: "movie-create", component: MovieCreate },
  {
    path: "/movie/:slug/update",
    name: "movie-update",
    component: MovieUpdate,
    props: true,
  },
  {
    path: "/movie/:slug/image-update",
    name: "movie-image-update",
    component: MovieImageUpdate,
    props: true,
  },
  {
    path: "/movie/create-list",
    name: "movie-list-create",
    component: MSListCreate,
    props: true,
  },
  {
    path: "/movie/:listSlug/update-list",
    name: "movie-list-update",
    component: MSListUpdate,
    props: true,
  },

  { path: "/serial", name: "serial-list", component: SerialList },
  { path: "/mongol-serial", name: "mongol-serial-list", component: SerialList },
  {
    path: "/serial/detail/:slug",
    name: "serial-detail",
    component: SerialDetail,
    props: true,
  },
  { path: "/serial/create", name: "serial-create", component: SerialCreate },
  {
    path: "/serial/update/:serialSlug",
    name: "serial-update",
    component: SerialUpdate,
    props: true,
  },
  {
    path: "/serial/:serialSlug/:seasonSlug/detail",
    name: "season-detail",
    component: SeasonDetail,
    props: true,
  },
  {
    path: "/serial/create-list",
    name: "serial-list-create",
    component: MSListCreate,
    props: true,
  },
  {
    path: "/serial/update-list/:listSlug",
    name: "serial-list-update",
    component: MSListUpdate,
    props: true,
  },
  { path: "/season/create", name: "season-create", component: SeasonCreate },
  {
    path: "/serial/season/update/:serialSlug/:seasonSlug",
    name: "season-update",
    component: SeasonUpdate,
    props: true,
  },
  { path: "/episode/create", name: "episode-create", component: EpisodeCreate },
  {
    path: "/serial/season/episode/update/:serialSlug/:seasonSlug/:episodeSlug",
    name: "episode-update",
    component: EpisodeUpdate,
    props: true,
  },
  {
    path: "/serial/season/image-update/:serialSlug/:seasonSlug",
    name: "season-image-update",
    component: SeasonImageUpdate,
    props: true,
  },
  {
    path: "/serial/:serialSlug/:seasonSlug/:episodeSlug/detail",
    name: "episode-detail",
    component: EpisodeDetail,
    props: true,
  },

  { path: "/journal", name: "journal-list", component: JournalList },
  {
    path: "/journal/:typeSlug/:subTypeSlug/:slug",
    name: "journal-detail",
    component: JournalDetail,
    props: true,
  },
  {
    path: "/journal/create",
    name: "journal-create",
    component: JournalCreate,
    meta: { requirePublisher: true },
  },
  {
    path: "/journal/:typeSlug/:subTypeSlug/:slug/update",
    name: "journal-update",
    component: JournalUpdate,
    props: true,
    meta: { requirePublisher: true },
  },

  {
    path: "/unpublished/artist",
    name: "unpublished-artist-list",
    component: ArtistList,
    meta: { requireContributer: true },
  },
  {
    path: "/unpublished/mongol-artist",
    name: "unpublished-mongol-artist-list",
    component: ArtistList,
    meta: { requireContributer: true },
  },
  {
    path: "/unpublished/movie",
    name: "unpublished-movie-list",
    component: MovieList,
    meta: { requireContributer: true },
  },
  {
    path: "/unpublished/mongol-movie",
    name: "unpublished-mongol-movie-list",
    component: MovieList,
    meta: { requireContributer: true },
  },
  {
    path: "/unpublished/short-movie",
    name: "unpublished-short-movie-list",
    component: MovieList,
    meta: { requireContributer: true },
  },
  {
    path: "/unpublished/mongol-short-movie",
    name: "unpublished-mongol-short-movie-list",
    component: MovieList,
    meta: { requireContributer: true },
  },
  {
    path: "/unpublished/serial",
    name: "unpublished-serial-list",
    component: SerialList,
    meta: { requireContributer: true },
  },
  {
    path: "/unpublished/mongol-serial",
    name: "unpublished-mongol-serial-list",
    component: SerialList,
    meta: { requireContributer: true },
  },
  {
    path: "/unpublished/journal",
    name: "unpublished-journal-list",
    component: JournalList,
    meta: { requireContributer: true },
  },

  {
    path: "/auto-add/artist",
    name: "add-artist",
    component: AddArtist,
    meta: { requireMainContributer: true },
  },
  {
    path: "/auto-add/movie",
    name: "add-movie",
    component: AddMovie,
    meta: { requireMainContributer: true },
  },
  {
    path: "/auto-add/serial",
    name: "add-serial",
    component: AddSerial,
    meta: { requireMainContributer: true },
  },
  {
    path: "/auto-update/serial",
    name: "update-serial",
    component: UpdateSerial,
    meta: { requireMainContributer: true },
  },

  {
    path: "/edit-aboutus",
    name: "edit-aboutus",
    component: EditAboutUs,
    meta: { requireAdmin: true },
  },
  {
    path: "/about-us",
    name: "about-us",
    component: AboutUs,
    meta: { requireAdmin: true },
  },

  {
    path: "/movie-review",
    name: "movie-review-list",
    component: GenericDetailedReviewList,
  },
  {
    path: "/movie-review/:userSlug/:movieSlug",
    name: "movie-review-detail",
    component: GenericDetailedReview,
    props: true,
  },
  {
    path: "/episode-review",
    name: "episode-review-list",
    component: GenericDetailedReviewList,
  },
  {
    path: "/episode-review/:userSlug/:serialSlug/:seasonSlug/:episodeSlug",
    name: "episode-review-detail",
    component: GenericDetailedReview,
    props: true,
  },

  {
    path: "/season-review",
    name: "season-review-list",
    component: GenericDetailedReviewList,
  },
  {
    path: "/season-review/:userSlug/:serialSlug/:seasonSlug",
    name: "season-review-detail",
    component: GenericDetailedReview,
    props: true,
  },
  { path: "*", name: "not-found-404", component: NotFound404 },
];

const router = new VueRouter({
  mode: "history",
  history: createWebHistory,
  routes,
});

router.beforeEach((to, from, next) => {
  let userHasToken = localStorage.getItem("token");
  let mfiNav = localStorage.getItem("mfiNav");

  if (mfiNav === "false") {
    store.commit("SET_MFINAVBAR", false);
  } else {
    store.commit("SET_MFINAVBAR", true);
  }

  if (to.matched.some((record) => record.meta.requireContributer)) {
    store.dispatch("getUserProfile").then(() => {
      if (!store.state.user.contributer) {
        next({ name: "not-found-404" });
      }
    });
  }

  if (to.matched.some((record) => record.meta.requireMainContributer)) {
    store.dispatch("getUserProfile").then(() => {
      if (!store.state.user.mainContributer) {
        next({ name: "not-found-404" });
      }
    });
  }

  if (to.matched.some((record) => record.meta.requirePublisher)) {
    store.dispatch("getUserProfile").then(() => {
      if (!store.state.user.publisher) {
        next({ name: "not-found-404" });
      }
    });
  }

  if (
    to.matched.some((record) => record.meta.requireLogin) &&
    userHasToken === null
  ) {
    next({ name: "login", query: { redirect: to.fullPath } });
  }

  if ((to.name === "login" || to.name === "signup") && userHasToken !== null)
    next({ name: "home" });
  else next();
});

export default router;
