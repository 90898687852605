<template>
	<div class="generic-list__box">
		<div class="generic-list__title">
			{{ serialListTitle }}
			<span class="generic-list__title__total">{{ total }}</span>
			цуврал
		</div>
		<v-card class="mb-2" elevation="0" outlined>
			<v-tabs color="light-blue darken-3 accent-4" fixed-tabs>
				<v-tab><v-icon>mdi-movie-search</v-icon></v-tab>
				<v-tab><v-icon>mdi-sort</v-icon></v-tab>
				<v-tab><v-icon>mdi-filter</v-icon></v-tab>
				<v-tab-item :key="1">
					<div class="generic-list__tab_block">
						<v-text-field label="Цуврал хайлт" v-model="serialSearch" @keyup.native.enter="submitFilter(true, (pageLoad = false))">
							<template v-slot:append>
								<v-btn class="generic-list__search_btn ma-1" rounded color="light-blue darken-3" dark type="submit" @click="submitFilter(true, (pageLoad = false))"><v-icon>mdi-magnify</v-icon></v-btn>
							</template>
						</v-text-field>
					</div>
				</v-tab-item>
				<v-tab-item :key="2">
					<div class="generic-list__tab_block">
						<v-select :items="serialSortItems" label="Үзүүлэх дараалал" v-model="serialSort" @change="submitFilter(true, (pageLoad = false))"></v-select>
					</div>
				</v-tab-item>
				<v-tab-item :key="3">
					<div class="generic-list__tab_block">
						<form v-on:submit.prevent="submitFilter(true, (pageLoad = false))">
							<div class="generic-list__filter_box">
								<div class="generic-list__filter_block">
									<v-card class="ma-1 pa-2" elevation="0" outlined>
										<v-select v-show="routeName === 'serial-list'" :items="serialCountryItems" label="Улс" v-model="serialCountry" item-text="country_mongol_name" :return-object="false" item-value="id"></v-select>
										<v-select :items="serialFormatItems" label="Цувралын формат" v-model="serialFormat"></v-select>
										<v-select :items="serialRatingItems" label="Цувралын рэйтинг" v-model="serialRating"></v-select>
										<v-combobox v-show="routeName === 'serial-list'" v-model="serialLanguageValue" :items="serialLanguageItems" label="Цувралын хэл" multiple item-text="language_mongol_name" :return-object="false"></v-combobox>
										<v-combobox v-model="serialGenreValue" :items="serialGenreItems" label="Цувралын жанр" multiple item-text="genre_mongol_name" :return-object="false"></v-combobox>
										<v-combobox v-model="serialSubGenreValue" :items="serialSubGenreItems" label="Цувралын дэд жанр" multiple item-text="sub_genre_mongol_name" :return-object="false"></v-combobox>
										<v-combobox v-model="serialTagValue" :items="serialTagItems" label="Цувралын таг" :search-input.sync="searchTag" item-text="tag_name" chips multiple :return-object="false"></v-combobox>
									</v-card>
								</div>
								<div class="generic-list__filter_block">
									<v-card class="ma-1 pa-2" elevation="0" outlined>
										<v-subheader>Цувралын үнэлгээ</v-subheader>
										<v-range-slider v-model="serialGradeValue" :min="serialMinGradeValue" :max="serialMaxGradeValue" hide-details class="align-center" @mouseup="serialGrade()">
											<template v-slot:prepend>
												<v-text-field :value="serialGradeValue[0]" class="mt-0 pt-0" hide-details single-line type="number" style="width: 60px" @change="$set(serialGradeValue, 0, $event)"></v-text-field>
											</template>
											<template v-slot:append>
												<v-text-field :value="serialGradeValue[1]" class="mt-0 pt-0" hide-details single-line type="number" style="width: 60px" @change="$set(serialGradeValue, 1, $event)"></v-text-field>
											</template>
										</v-range-slider>
										<v-subheader>Цувралын цацагдаж эхэлсэн он</v-subheader>
										<v-range-slider v-model="serialReleaseYearValue" :max="serialReleaseMaxYearValue" :min="serialReleaseMinYearValue" hide-details class="align-center" @mouseup="serialReleasedYear()">
											<template v-slot:prepend>
												<v-text-field :value="serialReleaseYearValue[0]" class="mt-0 pt-0" hide-details single-line type="number" style="width: 60px" @change="$set(serialReleaseYearValue, 0, $event)"></v-text-field>
											</template>
											<template v-slot:append>
												<v-text-field :value="serialReleaseYearValue[1]" class="mt-0 pt-0" hide-details single-line type="number" style="width: 60px" @change="$set(serialReleaseYearValue, 1, $event)"></v-text-field>
											</template>
										</v-range-slider>
										<v-subheader>Цувралын цацагдаж дууссан он</v-subheader>
										<v-range-slider v-model="serialFinishedYearValue" :max="serialFinishedMaxYearValue" :min="serialFinishedMinYearValue" hide-details class="align-center" @mouseup="serialFinishedYear()">
											<template v-slot:prepend>
												<v-text-field :value="serialFinishedYearValue[0]" class="mt-0 pt-0" hide-details single-line type="number" style="width: 60px" @change="$set(serialFinishedYearValue, 0, $event)"></v-text-field>
											</template>
											<template v-slot:append>
												<v-text-field :value="serialFinishedYearValue[1]" class="mt-0 pt-0" hide-details single-line type="number" style="width: 60px" @change="$set(serialFinishedYearValue, 1, $event)"></v-text-field>
											</template>
										</v-range-slider>
										<v-subheader>Цувралын дундаж үргэлжлэх хугацаа</v-subheader>
										<v-range-slider v-model="serialRuntimeValue" :min="serialMinRuntimeValue" :max="serialMaxRuntimeValue" hide-details class="align-center" @mouseup="serialRuntime()">
											<template v-slot:prepend>
												<v-text-field :value="serialRuntimeValue[0]" class="mt-0 pt-0" hide-details single-line type="number" style="width: 60px" @change="$set(serialRuntimeValue, 0, $event)"></v-text-field>
											</template>
											<template v-slot:append>
												<v-text-field :value="serialRuntimeValue[1]" class="mt-0 pt-0" hide-details single-line type="number" style="width: 60px" @change="$set(serialRuntimeValue, 1, $event)"></v-text-field>
											</template>
										</v-range-slider>
									</v-card>
									<br />
									<div class="d-flex flex-wrap justify-center">
										<v-btn large color="light-blue darken-3" dark type="submit" class="ma-1">Филтэрдэх</v-btn>
										<v-btn large color="#ffcc00" dark @click="resetFilter(null)" class="ma-1">Ресетлэх</v-btn>
									</div>
								</div>
							</div>
						</form>
					</div>
				</v-tab-item>
			</v-tabs>
		</v-card>
		<div class="generic-list__row" id="router_view">
			<SerialCard v-for="serial in serials" :key="serial.id" :serial="serial" />
		</div>
		<div class="text-center mt-2">
			<v-pagination v-model="page" :length="serialPage" @input="submitFilter(false, (pageLoad = false))" :total-visible="screenWidth"></v-pagination>
		</div>
	</div>
</template>

<script>
import SerialCard from "@/components/serial/ListCard.vue";
import axios from "axios";

export default {
	components: {
		SerialCard,
	},
	data() {
		return {
			routeName: this.$route.name,
			published: false,
			serialListTitle: "",
			serialSearch: "",
			serialSortItems: [
				{ text: "Эхэнд нэмэгдсэнийг эхэнд", value: 1 },
				{ text: "Эхэнд нэмэгдсэнийг сүүлд", value: 2 },
				{ text: "Өндөр үнэлгээтэйг эхэнд", value: 3 },
				{ text: "Өндөр үнэлгээтэйг сүүлд", value: 4 },
				{ text: "Их үзэгчтэйг эхэнд", value: 5 },
				{ text: "Их үзэгчтэйг сүүлд", value: 6 },
				{ text: "Цагаан толгойн дарааллаар", value: 7 },
				{ text: "Цагаан толгойн эсрэг дарааллаар", value: 8 },
			],
			serialSort: 0,
			serialCountryItems: [],
			serialCountry: 0,
			serialFormatItems: [
				{ text: "Уран сайхны кино", value: 1 },
				{ text: "Баримтат кино", value: 2 },
				{ text: "Хүүхэлдэйн кино", value: 3 },
				{ text: "Эксперименталь", value: 4 },
			],
			serialFormat: 0,
			serialRatingItems: [
				{ text: "Not Rated", value: 1 },
				{ text: "General Audiences", value: 2 },
				{ text: "Parental Guidance Suggested", value: 3 },
				{ text: "Restricted", value: 4 },
			],
			serialRating: 0,
			serialLanguageItems: [],
			serialLanguageValue: [],
			serialGenreValue: [],
			serialGenreItems: [],
			serialSubGenreValue: [],
			serialSubGenreItems: [],
			serialTagValue: [],
			serialTagItems: [],
			searchTag: null,
			serialMinGradeValue: 0,
			serialMaxGradeValue: 100,
			serialGradeValue: [0, 100],
			serialGradeFlag: 0,
			serialReleaseMinYearValue: 1900,
			serialReleaseMaxYearValue: 2023,
			serialReleaseYearValue: [1900, 2023],
			serialReleaseYearFlag: 0,
			serialFinishedMinYearValue: 1900,
			serialFinishedMaxYearValue: 2023,
			serialFinishedYearValue: [1900, 2023],
			serialFinishedYearFlag: 0,
			serialMinRuntimeValue: 0,
			serialMaxRuntimeValue: 100,
			serialRuntimeValue: [0, 100],
			serialRuntimeFlag: 0,
			serials: [],
			total: null,
			page: 1,
			serialPage: 0,
			parameters: {},
			pageLoad: true,
		};
	},
	watch: {
		searchTag(val) {
			if (val) {
				axios.get("/tag/api-tag-list/", { params: { tag: val } }).then((response) => {
					this.serialTagItems = response.data.tag_list;
				});
			}
		},
		"$route.name"() {
			this.resetFilter(this.$route.name);
		},
		serialGenreValue(val) {
			if (val.length > 5) {
				this.$nextTick(() => this.serialGenreValue.pop());
			}
		},
		serialLanguageValue(val) {
			if (val.length > 5) {
				this.$nextTick(() => this.serialLanguageValue.pop());
			}
		},
		serialSubGenreValue(val) {
			if (val.length > 5) {
				this.$nextTick(() => this.serialSubGenreValue.pop());
			}
		},
		serialTagValue(val) {
			if (val.length > 5) {
				this.$nextTick(() => this.serialTagValue.pop());
			}
		},
	},
	computed: {
		screenWidth() {
			var w = window.innerWidth;
			return w < 481 && w > 319 ? 5 : 7;
		},
	},
	methods: {
		resetFilter(route) {
			this.serialSearch = "";
			this.serialSort = 0;
			this.serialCountry = 0;
			this.serialFormat = 0;
			this.serialRating = 0;
			this.serialLanguageValue = [];
			this.serialGenreValue = [];
			this.serialSubGenreValue = [];
			this.serialTagValue = [];
			this.serialGradeValue = [0, 100];
			this.serialGradeFlag = 0;
			this.serialReleaseYearValue = [1900, 2023];
			this.serialReleaseYearFlag = 0;
			this.serialFinishedYearValue = [1900, 2023];
			this.serialFinishedYearFlag = 0;
			this.serialRuntimeValue = [0, 100];
			this.serialRuntimeFlag = 0;
			this.serials = [];
			this.page = 1;
			if (route !== null) this.routeName = route;
			this.pageLoad = false;
			this.submitFilter();
		},
		serialGrade() {
			this.serialGradeFlag = 1;
		},
		serialReleasedYear() {
			this.serialReleaseYearFlag = 1;
		},
		serialFinishedYear() {
			this.serialFinishedYearFlag = 1;
		},
		serialRuntime() {
			this.serialRuntimeFlag = 1;
		},
		getLanguage() {
			axios.get("/language/api-language-list/").then((response) => {
				this.serialLanguageItems = response.data.language_list;
			});
		},
		getGenres() {
			axios.get("/genre/api-genre-list/").then((response) => {
				this.serialGenreItems = response.data.genre_list;
			});
		},
		getSubGenres() {
			axios.get("/genre/api-subgenre-list/").then((response) => {
				this.serialSubGenreItems = response.data.subgenre_list;
			});
		},
		getProductionCountries() {
			axios.get("/country/api-production-country-list/").then((response) => {
				this.serialCountryItems = response.data.country_list;
			});
		},
		submitFilter(pageAssign) {
			setTimeout(function () {
				var el = document.getElementById("router_view");
				if (typeof el !== "undefined" && el !== null) {
					el.scrollIntoView({ behavior: "smooth" });
				}
			}, 500);

			if (pageAssign === true) this.page = 1;

			let currentPage = this.$route.query.page;
			let currentSort = this.$route.query.sort;
			let currentCountry = this.$route.query.country;
			let currentFormat = this.$route.query.format;
			let currentRating = this.$route.query.rating;
			let currentLang = this.$route.query.lang ? JSON.parse(this.$route.query.lang) : [];
			let currentGenre = this.$route.query.genre ? JSON.parse(this.$route.query.genre) : [];
			let currentSubGenre = this.$route.query.subgenre ? JSON.parse(this.$route.query.subgenre) : [];
			let currentTag = this.$route.query.tag ? JSON.parse(this.$route.query.tag) : [];
			let currentGrade = this.$route.query.grade ? JSON.parse(this.$route.query.grade) : [0, 100];
			let currentReYear = this.$route.query.reyear ? JSON.parse(this.$route.query.reyear) : [1900, 2023];
			let currentFiYear = this.$route.query.fiyear ? JSON.parse(this.$route.query.fiyear) : [1900, 2023];
			let currentRuntime = this.$route.query.runtime ? JSON.parse(this.$route.query.runtime) : [0, 100];

			if (this.pageLoad) {
				if (this.page !== parseInt(currentPage)) {
					if (currentPage == undefined || isNaN(currentPage)) this.page = 1;
					else this.page = parseInt(currentPage);
				}

				if (!(this.serialSearch === this.$route.query.search)) {
					this.serialSearch = this.$route.query.search;
				}

				if (this.serialSort !== parseInt(currentSort)) {
					if (currentSort == undefined || isNaN(currentSort)) this.serialSort = 0;
					else this.serialSort = parseInt(currentSort);
				}

				if (this.serialCountry !== parseInt(currentCountry)) {
					if (currentCountry == undefined || isNaN(currentCountry)) this.serialCountry = 0;
					else this.serialCountry = parseInt(currentCountry);
				}

				if (this.serialFormat !== parseInt(currentFormat)) {
					if (currentFormat == undefined || isNaN(currentFormat)) this.serialFormat = 0;
					else this.serialFormat = parseInt(currentFormat);
				}

				if (this.serialRating !== parseInt(currentRating)) {
					if (currentRating == undefined || isNaN(currentRating)) this.serialRating = 0;
					else this.serialRating = parseInt(currentRating);
				}

				if (currentLang !== undefined && this.serialLanguageValue !== currentLang) {
					if (currentLang == []) this.serialLanguageValue = [];
					else this.serialLanguageValue = currentLang;
				}

				if (currentGenre !== undefined && this.serialGenreValue !== currentGenre) {
					if (currentGenre == []) this.serialGenreValue = [];
					else this.serialGenreValue = currentGenre;
				}

				if (currentSubGenre !== undefined && this.serialSubGenreValue !== currentSubGenre) {
					if (currentSubGenre == []) this.serialSubGenreValue = [];
					else this.serialSubGenreValue = currentSubGenre;
				}

				if (currentTag !== undefined && this.serialTagValue !== currentTag) {
					if (currentTag == []) this.serialTagValue = [];
					else this.serialTagValue = currentTag;
				}

				if (currentGrade !== undefined && JSON.stringify(this.serialGradeValue) !== JSON.stringify(currentGrade)) {
					if (JSON.stringify(currentGrade) == JSON.stringify([0, 100])) (this.serialGradeValue = [0, 100]), (this.serialGradeFlag = 0);
					else (this.serialGradeValue = currentGrade), (this.serialGradeFlag = 1);
				}

				if (currentReYear !== undefined && JSON.stringify(this.serialReleaseYearValue) !== JSON.stringify(currentReYear)) {
					if (JSON.stringify(currentReYear) === JSON.stringify([1900, 2023])) (this.serialReleaseYearValue = [1900, 2023]), (this.serialReleaseYearFlag = 0);
					else (this.serialReleaseYearValue = currentReYear), (this.serialReleaseYearFlag = 1);
				}

				if (currentFiYear !== undefined && JSON.stringify(this.serialFinishedYearValue) !== JSON.stringify(currentFiYear)) {
					if (JSON.stringify(currentFiYear) === JSON.stringify([1900, 2023])) (this.serialFinishedYearValue = [1900, 2023]), (this.serialFinishedYearFlag = 0);
					else (this.serialFinishedYearValue = currentFiYear), (this.serialFinishedYearFlag = 1);
				}

				if (currentRuntime !== undefined && JSON.stringify(this.serialRuntimeValue) !== JSON.stringify(currentRuntime)) {
					if (JSON.stringify(currentRuntime) === JSON.stringify([0, 100])) (this.serialRuntimeValue = [0, 100]), (this.serialRuntimeFlag = 0);
					else (this.serialRuntimeValue = currentRuntime), (this.serialRuntimeFlag = 1);
				}
			}

			this.parameters = {
				page: this.page,
				published: this.routeName.indexOf("unpublished") === -1,
				search: this.serialSearch,
				sort: this.serialSort,
				country: this.serialCountry,
				format: this.serialFormat,
				rating: this.serialRating,
				language: this.serialLanguageValue,
				genre: this.serialGenreValue,
				subgenre: this.serialSubGenreValue,
				tag: this.serialTagValue,
				grade: this.serialGradeValue,
				gradeFlag: this.serialGradeFlag,
				releasedYear: this.serialReleaseYearValue,
				releasedYearFlag: this.serialReleaseYearFlag,
				finishedYear: this.serialFinishedYearValue,
				finishedYearFlag: this.serialFinishedYearFlag,
				runtime: this.serialRuntimeValue,
				runtimeFlag: this.serialRuntimeFlag,
			};

			if (this.routeName === "serial-list" || this.routeName === "unpublished-serial-list") {
				if (this.routeName === "serial-list") {
					this.serialListTitle = "Олон улсын";
					document.title = "Цуврал жагсаалт";
				} else if (this.routeName === "unpublished-serial-list") {
					this.serialListTitle = "Олон улсын нийтлэгдээгүй";
					document.title = "Нийтлэгдээгүй цуврал жагсаалт";
				}
				this.parameters.countryFlag = 1;
			} else if (this.routeName === "mongol-serial-list" || this.routeName === "unpublished-mongol-serial-list") {
				if (this.routeName === "mongol-serial-list") {
					this.serialListTitle = "Монгол";
					document.title = "Монгол цувралууд";
				} else if (this.routeName === "unpublished-mongol-serial-list") {
					this.serialListTitle = "Монгол нийтлэгдээгүй";
					document.title = "Нийтлэгдээгүй Монгол цуврал жагсаалт";
				}
				this.parameters.countryFlag = 2;
			}

			let querySet = {
				page: this.page,
			};

			if (this.serialSearch !== "") {
				Object.assign(querySet, { search: this.serialSearch });
			}

			if (this.serialSort !== 0) {
				Object.assign(querySet, { sort: this.serialSort });
			}

			if (this.serialCountry !== 0) {
				Object.assign(querySet, { country: this.serialCountry });
			}

			if (this.serialFormat !== 0) {
				Object.assign(querySet, { format: this.serialFormat });
			}

			if (this.serialRating !== 0) {
				Object.assign(querySet, { rating: this.serialRating });
			}

			if (!!this.serialLanguageValue.length) {
				Object.assign(querySet, { lang: JSON.stringify(this.serialLanguageValue) });
			}

			if (!!this.serialGenreValue.length) {
				Object.assign(querySet, { genre: JSON.stringify(this.serialGenreValue) });
			}

			if (!!this.serialSubGenreValue.length) {
				Object.assign(querySet, { subgenre: JSON.stringify(this.serialSubGenreValue) });
			}

			if (!!this.serialTagValue.length) {
				Object.assign(querySet, { tag: JSON.stringify(this.serialTagValue) });
			}

			if (JSON.stringify(this.serialGradeValue) !== JSON.stringify([0, 100])) {
				Object.assign(querySet, { grade: JSON.stringify(this.serialGradeValue) });
			}

			if (JSON.stringify(this.serialReleaseYearValue) !== JSON.stringify([1900, 2023])) {
				Object.assign(querySet, { year: JSON.stringify(this.serialReleaseYearValue) });
			}

			if (JSON.stringify(this.serialFinishedYearValue) !== JSON.stringify([1900, 2023])) {
				Object.assign(querySet, { year: JSON.stringify(this.serialFinishedYearValue) });
			}

			if (JSON.stringify(this.serialRuntimeValue) !== JSON.stringify([0, 100])) {
				Object.assign(querySet, { runtime: JSON.stringify(this.serialRuntimeValue) });
			}

			axios
				.get("/serial/serial-list/", { params: this.parameters })
				.then((response) => {
					(this.serials = response.data.serial_list), (this.total = response.data.serial_total), (this.serialPage = response.data.serial_page);
				})
				.finally(() => {
					this.$router.push({ name: this.routeName, query: querySet }).catch(() => {});
				});
		},
	},
	created() {
		this.getLanguage();
		this.getGenres();
		this.getSubGenres();
		this.getProductionCountries();
		this.submitFilter();
	},
};
</script>
<style scoped src="@/assets/styles/generic-list.css"></style>
