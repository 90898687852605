<template>
    <nav>
        <v-toolbar tile dense color="#FFF" dark height="100" class="hidden-sm-and-down">
            <div class="d-flex align-center">
                <router-link :to="{ name: 'home' }"
                    ><v-img alt="MFI Logo" class="shrink mr-2" contain src="@/assets/mfi_logo.png" transition="scale-transition"
                /></router-link>
            </div>
            <v-spacer></v-spacer>
            <v-btn x-small depressed color="white" class="primary--text">
                <router-link class="navbar-text" :to="{ name: 'home' }">Нүүр</router-link>
            </v-btn>
            <v-btn x-small depressed color="white" class="primary--text">
                <a class="navbar-text" href="https://ggiff.mfi.mn" target="_blank" rel="noopener noreferrer">GGIFF</a>
            </v-btn>
            <v-btn x-small depressed color="white" class="primary--text">
                <router-link
                    class="navbar-text"
                    :to="{ name: 'journal-detail', params: { typeSlug: 'news', subTypeSlug: 'news', slug: 'ankhnii-alkham' } }"
                    >Анхны алхам</router-link
                >
            </v-btn>
            <v-menu v-for="item in activeMainItems" :key="item.title" offset-y attach>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn x-small depressed color="white" class="primary--text navbar-text" v-bind="attrs" v-on="on"
                        >{{ item.title }}<v-icon>mdi-menu-down</v-icon></v-btn
                    >
                </template>
                <v-list>
                    <span v-for="(child, index) in item.items" :key="index">
                        <v-divider v-show="child.divider"></v-divider>
                        <v-subheader v-show="child.subheader">{{ child.subheader }}</v-subheader>
                        <v-list-item v-if="child.title === 'Анхны алхам'"
                            ><router-link
                                class="navbar-text"
                                :to="{ name: child.url, params: { typeSlug: child.typeSlug, subTypeSlug: child.subTypeSlug, slug: child.slug } }"
                                >{{ child.title }}</router-link
                            ></v-list-item
                        >

                        <v-list-item v-else
                            ><router-link class="navbar-text" :to="{ name: child.url, params: { slug: child.slug, year: child.year } }">{{
                                child.title
                            }}</router-link></v-list-item
                        >
                    </span>
                </v-list>
            </v-menu>
            <v-menu offset-y attach :close-on-content-click="checkMenu">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn x-small depressed color="white" class="primary--text navbar-text" v-bind="attrs" v-on="on"
                        >Хэрэглэгч<v-icon>mdi-menu-down</v-icon></v-btn
                    >
                </template>
                <v-list>
                    <v-list-item-group>
                        <v-list-item v-for="(item, index) in activeUserItems" :key="index" @click="menuClose = true">
                            <v-list-item-content>
                                <router-link class="navbar-text" v-show="item.url" :to="{ name: item.url, params: { slug: user.slug } }">{{
                                    item.title
                                }}</router-link>
                                <v-divider v-show="!item.url"></v-divider>
                                <v-subheader v-show="!item.url">{{ item.title }}</v-subheader>
                                <v-list-item-content v-for="(child, index) in item.items" :key="index" class="navbar-text" v-show="!item.url">
                                    <router-link class="navbar-text" :to="{ name: child.url, params: { slug: child.slug, year: child.year } }">{{
                                        child.title
                                    }}</router-link>
                                </v-list-item-content>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                    <span v-show="user.isAuthenticated">
                        <v-divider></v-divider>
                        <v-list-item v-if="user.isAuthenticated && user.contributer">
                            <div class="navbar-text">Админбар</div>
                            <div class="admin_bar__hide">
                                <v-switch v-model="subAdminBar" inset dense @click="menuClose = false"></v-switch>
                            </div>
                        </v-list-item>
                        <v-list-item>
                            <a
                                class="navbar-text"
                                @click="
                                    logout();
                                    menuClose = true;
                                "
                                >Системээс гарах</a
                            >
                            <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
                        </v-list-item>
                    </span>
                </v-list>
            </v-menu>
            <v-btn x-small depressed color="white" class="primary--text" @click="mfiLanguageChange(language)">
                <v-icon small>mdi-earth</v-icon><span class="navbar-text">{{ checkLanguage }}</span>
            </v-btn>
            <v-btn @click="mfiNavbarChange(mfiNavbar)" class="navbar_switch_btn">
                <span v-if="mfiNavbar">Кино сан</span> <span v-else-if="!mfiNavbar">MFI</span>
            </v-btn>
        </v-toolbar>
        <v-toolbar tile dense color="#FFF" height="100" class="hidden-md-and-up">
            <div class="d-flex align-center">
                <router-link :to="{ name: 'home' }"
                    ><v-img alt="MFI Logo" class="shrink mr-2" contain src="@/assets/mfi_logo.png" transition="scale-transition"
                /></router-link>
            </div>
            <v-spacer></v-spacer>

            <v-app-bar-nav-icon class="navbar_app_icon" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

            <v-navigation-drawer v-model="drawer" absolute temporary app left>
                <v-list nav>
                    <v-list-group>
                        <template v-slot:activator>
                            <v-list-item-avatar>
                                <v-img v-show="user.isAuthenticated" :src="baseUrl + user.image" />
                                <v-icon v-show="!user.isAuthenticated" class="navbar_acc_circle">mdi-account-circle</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-title class="navbar-text"
                                ><span v-show="user.isAuthenticated">{{ user.name }}</span
                                ><span v-show="!user.isAuthenticated">Хэрэглэгч</span></v-list-item-title
                            >
                        </template>
                        <v-list>
                            <v-list-group v-for="item in activeUserItems" :key="item.title" :append-icon="item.icon">
                                <v-divider v-show="item.divider"></v-divider>
                                <template v-slot:activator>
                                    <v-list-item-content>
                                        <router-link class="navbar-text" v-show="item.url" :to="{ name: item.url, params: { slug: user.slug } }">{{
                                            item.title
                                        }}</router-link>
                                        <v-list-item-title v-show="!item.url" class="navbar-text" v-text="item.title"></v-list-item-title>
                                    </v-list-item-content>
                                </template>
                                <span v-for="(child, index) in item.items" :key="index">
                                    <v-divider v-show="child.divider"></v-divider>
                                    <v-subheader v-show="child.subheader">{{ child.subheader }}</v-subheader>
                                    <v-list-item
                                        ><router-link
                                            class="mobile-navbar-text"
                                            :to="{ name: child.url, params: { slug: child.slug, year: child.year } }"
                                            >{{ child.title }}</router-link
                                        ></v-list-item
                                    >
                                </span>
                            </v-list-group>
                            <v-divider></v-divider>
                            <v-list-item v-if="user.isAuthenticated && user.contributer">
                                <div class="navbar-text">Админбар</div>
                                <div class="admin_bar__hide">
                                    <v-switch v-model="subAdminBar" inset dense></v-switch>
                                </div>
                            </v-list-item>
                            <v-list-item v-if="user.isAuthenticated">
                                <a class="navbar-text" @click="logout()">Системээс гарах</a>
                                <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
                            </v-list-item>
                        </v-list>
                    </v-list-group>
                    <v-divider></v-divider>
                    <v-list-item><router-link class="navbar-text" :to="{ name: 'home' }">Нүүр</router-link></v-list-item>
                    <v-list-item>
                        <a class="navbar-text" href="https://ggiff.mfi.mn" target="_blank" rel="noopener noreferrer">GGIFF</a>
                    </v-list-item>
                    <v-list-item>
                        <router-link
                            class="navbar-text"
                            :to="{ name: 'journal-detail', params: { typeSlug: 'news', subTypeSlug: 'news', slug: 'ankhnii-alkham' } }"
                            >Анхны алхам</router-link
                        >
                    </v-list-item>

                    <v-list-group v-for="item in activeMainItems" :key="item.title">
                        <template v-slot:activator>
                            <v-list-item-content>
                                <v-list-item-title class="navbar-text" v-text="item.title"></v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <span v-for="(child, index) in item.items" :key="index">
                            <v-divider v-show="child.divider"></v-divider>
                            <v-subheader v-show="child.subheader">{{ child.subheader }}</v-subheader>
                            <v-list-item
                                ><router-link class="mobile-navbar-text" :to="{ name: child.url, params: { slug: child.slug, year: child.year } }">{{
                                    child.title
                                }}</router-link></v-list-item
                            >
                        </span>
                    </v-list-group>
                    <v-btn x-small depressed color="white" class="primary--text mt-3 mb-5" @click="mfiLanguageChange(language)">
                        <v-icon small>mdi-earth</v-icon><span class="navbar-text">{{ checkLanguage }}</span>
                    </v-btn>
                    <v-btn block @click="mfiNavbarChange(mfiNavbar)" class="navbar_switch_btn">
                        <span v-if="mfiNavbar">Кино сан</span><span v-else-if="!mfiNavbar">MFI</span>
                    </v-btn>
                </v-list>
            </v-navigation-drawer>
        </v-toolbar>
    </nav>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            language: "MN",
            subAdminBar: false,
            menuClose: true,
            drawer: null,
            baseUrl: process.env.VUE_APP_BASEURL,
            userItems: [
                { authenticated: false, title: "Нэвтрэх", url: "login", icon: null },
                { authenticated: false, title: "Бүртгүүлэх", url: "signup", icon: null },
                { authenticated: true, title: "Миний профайл", url: "user-feed", icon: null },
                { authenticated: true, title: "Миний файлууд", url: "user-images", icon: null },
                { authenticated: true, title: "Кино жагсаалт үүсгэх", url: "movie-list-create", icon: null },
                { authenticated: true, title: "Цувралаар жагсаалт үүсгэх", url: "serial-list-create", icon: null },
                { publisher: true, title: "Нийтлэл илгээх", url: "journal-create", icon: null },
                {
                    authenticated: true,
                    title: "Үүсгэх",
                    items: [
                        { title: "Уран бүтээлч үүсгэх", url: "artist-create" },
                        { title: "Кино үүсгэх", url: "movie-create", divider: true },
                        { title: "Цуврал үүсгэх", url: "serial-create", divider: true },
                        { title: "Цувралд бүлэг үүсгэх", url: "season-create" },
                        { title: "Цувралд анги үүсгэх", url: "episode-create" },
                    ],
                },
                {
                    contributer: true,
                    title: "Нэмэх",
                    items: [
                        { title: "Уран бүтээлч нэмэх", url: "add-artist" },
                        { title: "Кино нэмэх", url: "add-movie", divider: true },
                        { title: "Цуврал нэмэх", url: "add-serial", divider: true },
                        { title: "Цуврал шинэчлэх", url: "update-serial" },
                    ],
                },
            ],
            mainItems: [
                {
                    menu: true,
                    title: "MFI",
                    items: [
                        { title: "Төслүүд", url: "under-construction" },
                        { title: "Бидний тухай", url: "about-us" },
                    ],
                },
                {
                    menu: false,
                    title: "Сэтгүүл",
                    items: [
                        { title: "Нийтлэл", url: "journal-list" },
                        { title: "Кино шүүмжүүд", url: "movie-review-list" },
                        { title: "Эпизод шүүмжүүд", url: "episode-review-list" },
                    ],
                },
                {
                    menu: false,
                    title: "Уран бүтээлчид",
                    items: [
                        { title: "Олон улсын", url: "artist-list" },
                        { title: "Монгол", url: "mongol-artist-list" },
                    ],
                },
                {
                    menu: false,
                    title: "Кино",
                    items: [
                        { title: "Бүрэн хэмжээний", url: "movie-list" },
                        { title: "Монгол", url: "mongol-movie-list" },
                        { title: "Богино хэмжээний", url: "short-movie-list", divider: true },
                        { title: "Монгол богино хэмжээний", url: "mongol-short-movie-list" },
                        { title: "Цуврал", url: "serial-list", divider: true },
                        { title: "Монгол цуврал", url: "mongol-serial-list" },
                    ],
                },
                {
                    admin: true,
                    title: "Админ",
                    items: [
                        { title: "Танилцуулага засах", url: "edit-aboutus" },
                        { title: "Кино наадам нэмэх", url: "award-create" },
                        { title: "Тухайн оны наадам нэмэх", url: "annual-award-create" },
                    ],
                },
                {
                    contributer: true,
                    title: "Засвар",
                    items: [
                        { title: "Уран бүтээлчид", url: "unpublished-artist-list", subheader: "Олон улс", divider: true },
                        { title: "Кинонууд", url: "unpublished-movie-list" },
                        { title: "Богино кинонууд", url: "unpublished-short-movie-list" },
                        { title: "Цувралууд", url: "unpublished-serial-list" },
                        { title: "Уран бүтээлчид", url: "unpublished-mongol-artist-list", subheader: "Монгол", divider: true },
                        { title: "Кинонууд", url: "unpublished-mongol-movie-list" },
                        { title: "Богино кинонууд", url: "unpublished-mongol-short-movie-list" },
                        { title: "Цувралууд", url: "unpublished-mongol-serial-list" },
                        { title: "Нийтлэгдээгүй сэтгүүлүүд", url: "unpublished-journal-list", subheader: "Нийтлэл", divider: true },
                    ],
                },
            ],
        };
    },
    watch: {
        subAdminBar() {
            this.$store.commit("SET_USER_ADMINBAR", this.subAdminBar);
        },
    },
    computed: {
        checkMenu() {
            return this.menuClose;
        },
        checkLanguage() {
            return this.language === "MN" ? "EN" : "MN";
        },
        activeMainItems() {
            let status = this.mfiNavbar;
            let userAuthenticated = this.user.isAuthenticated;
            let userAdmin = this.user.admin;
            let userContributer = this.user.contributer;

            return this.mainItems.filter(function (u) {
                return (
                    u.menu === status || (u.menu === undefined && userAuthenticated && (u.admin === userAdmin || u.contributer === userContributer))
                );
            });
        },
        activeUserItems() {
            let userAuthenticated = this.user.isAuthenticated;
            let userPublisher = this.user.publisher;
            let userMainContributer = this.user.mainContributer;

            return this.userItems.filter(function (u) {
                return (
                    u.authenticated === userAuthenticated ||
                    (userAuthenticated && (u.contributer === userMainContributer || u.publisher === userPublisher))
                );
            });
        },
        ...mapState(["user", "mfiNavbar"]),
    },
    methods: {
        mfiNavbarChange(status) {
            this.$store.commit("SET_MFINAVBAR", !status);
        },
        mfiLanguageChange() {
            this.language === "MN" ? (this.language = "EN") : (this.language = "MN");
            this.$store.commit("SET_LANGUAGE", this.language);
        },
        logout() {
            this.$store.commit("SET_ISLOADING", true);
            this.$store.dispatch("userLogout").then(() => {
                this.$store.commit("SET_ISLOADING", false);
            });
        },
    },
};
</script>

<style scoped>
* {
    text-transform: none !important;
}

.navbar-text {
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    color: #0066b5;
}

.navbar_switch_btn {
    font-size: 14px;
    background-color: #da2032 !important;
    color: #fff !important;
    font-weight: 800;
}

.navbar_app_icon {
    color: #0066b5 !important;
    font-size: 48px;
}

.navbar_acc_circle {
    color: #0066b5 !important;
    width: 64px;
}

.v-list-group--no-action > .v-list-group__items > .v-list-item {
    padding-left: 30px;
}

/* test */

.mobile-navbar-text {
    margin: 0 10px 0 10px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    color: #0066b5;
}

.admin_bar__hide {
    padding-top: 20px;
    padding-left: 10px;
}
</style>
