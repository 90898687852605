<template>
	<div class="movieMargin" v-show="movieList != ''">
		<div class="artist__legend_bar"><strong>Кино уран бүтээл</strong></div>
		<span v-for="(list, index) in movieList" :key="index">
			<div class="artist__year">
				{{ list["year"] }}
				<span v-if="index + 1 < movieList.length">- {{ list["year"] + 9 }}</span>
				<span v-else-if="died"></span>
				<span v-else>- одоог хүртэл</span>
			</div>
			<div class="row">
				<GenericMovieCard v-for="(movie, index) in list['movies']" :key="index" :type="'movie'" :slug="movie['movies'].movie_slug" :poster="movie['movies'].movie_poster" :year="movie['movies'].year" :role="movie['movies'].role" :job="movie['movies'].job" />
			</div>
		</span>
	</div>
</template>

<script>
import axios from "axios";
import GenericMovieCard from "@/components/artist/GenericMovieCard.vue";

export default {
	props: {
		slug: String,
		died: Number,
	},
	components: {
		GenericMovieCard,
	},
	data() {
		return {
			artistSlug: this.slug,
			movieList: [],
		};
	},
	methods: {
		movieArtists() {
			axios.get("/artist/api-artist-movies/", { params: { slug: this.artistSlug } }).then((response) => {
				this.movieList = response.data.movies;
			});
		},
	},
	created() {
		this.movieArtists();
	},
	watch: {
		slug() {
			this.movieList = [];
			this.artistSlug = this.slug;
			this.movieArtists();
		},
	},
};
</script>

<style scoped>
.movieMargin {
	margin: 20px 5px 0;
}

.artist__legend_bar {
	color: #0066b5;
	font-size: 28px;
	text-transform: uppercase;
	font-weight: 300;
	margin: auto;
	width: 100%;
}

.row {
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	gap: 0.5rem;
	width: 100%;
	padding: 0.5rem;
	margin: 0 auto;
}

.artist__year {
	margin: 20px 0;
	width: 100%;
	font-size: 36px;
	font-style: italic;
	text-transform: uppercase;
}

@media (min-width: 481px) {
	.row {
		grid-template-columns: repeat(8, minmax(0, 1fr));
	}
}
</style>
